import * as React from "react";
import {
  Box,
  Grid,
  AppBar,
  Toolbar,
  Container,
  Button,
  Typography,
} from "@mui/material";

import BgImage from "./assets/images/BGImage.jpg";
import Logo from "./assets/images/TviekFilmsLogo.png";
import SatishPhoto from "./assets/images/satishphotob&w.png";
import SwetaPhoto from "./assets/images/swetaphotob&w.png";

function App() {
  const scrollToAboutUs = () => {
    const aboutUsSection = document.getElementById("about-us-section");

    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToDirector = () => {
    const aboutUsSection = document.getElementById("Director-section");

    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const pages = [
    {
      id: 1,
      title: "About Us",
      action: scrollToAboutUs,
    },
    {
      id: 1,
      title: "Directors",
      action: scrollToDirector,
    },
  ];

  const phoneNumber = "+918905146473"
  const handleCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const emailAddress = "tviekfilms@gmail.com"
  const handleEmail = () => {
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <React.Fragment>
      <Grid
        sx={{
          backgroundImage: `url(${BgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { xs: "40vh", sm: "55vh", md: "100vh" },
        }}
      >
        <AppBar
          position="static"
          sx={{ bgcolor: "transparent", boxShadow: "none" }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {pages.map((page) => (
                  <Button
                    key={page.id}
                    sx={{ my: 2, color: "white", display: "block" }}
                    onClick={page.action}
                  >
                    {page.title}
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Grid
          sx={{
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="Logo"
            src={Logo}
            loading="lazy"
            style={{
              width: "200px",
              height: "100px",
              maxWidth: "100%",
              maxHeight: "100%",
              minWidth: "100px",
              minHeight: "50px",
            }}
          />
        </Grid>
      </Grid>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <section id="about-us-section">
                <Typography variant="h4" gutterBottom>
                  About Us
                </Typography>
                <Typography variant="body1" paragraph>
                  Founded by Sweta N.Kutty and Satish K.Nair, two dynamic minds
                  with a shared passion for creativity and innovation. Tviek
                  Films stands at the forefront of modern advertising, films and
                  web series. With a combined experience of 30+ years in
                  directing and producing compelling visual narratives, they
                  bring a unique perspective to every campaign they undertake.
                </Typography>
                <Typography variant="body1" paragraph>
                  At Tviek Films, we believe in the power of storytelling to
                  captivate audiences and drive meaningful engagement. Our
                  approach blends cinematic flair with strategic insights,
                  ensuring that each project not only meets but exceeds
                  expectations. From concept development to final execution, we
                  are committed to deliver results that resonates.
                </Typography>
                <Typography variant="body1" paragraph>
                  Driven by a relentless pursuit of excellence, Tviek Films
                  embraces challenges as opportunities to push boundaries and
                  redefine industry standards. Whether it's crafting a
                  television commercial, designing a digital campaign, or
                  producing viral content, our team is dedicated to craft
                  memorable experiences that leaves a lasting impression.
                </Typography>
              </section>
              <section id="Director-section">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <img
                      alt="Logo"
                      src={SwetaPhoto}
                      loading="lazy"
                      style={{
                        maxWidth: "100%", // Adjust max width as needed
                        maxHeight: "100%", // Adjust max height as needed
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={6}>
                      {/* Director's Bio */}
                      <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                          Director
                        </Typography>
                        <Typography variant="body1" paragraph>
                          20 years ago with the aspiration of becoming a
                          filmmaker to create magic on the screen; Sweta started
                          her journey with well-recognized ad films firm Ramesh
                          Deo Productions as a trainee. To strengthen the art of
                          storytelling she completed Post-graduation in Film and
                          Television from Xavier Institute of Mass
                          Communication, Mumbai.
                        </Typography>
                        <Typography variant="body1" paragraph>
                          She started her journey as casting director for Mr
                          Shoojit Sircar and went on to assist him as Associate
                          Director in 100+ TV Commercials and Feature Films for
                          12+ years. She worked as an Associate director in the
                          film ‘Piku’ and as Associate & 2nd Unit Director in
                          the movie ‘October’.
                        </Typography>
                        <Typography variant="body1">
                          Since the past 6+ years she is working as an
                          independent Ad Film Director directing TV Commercials
                          for brands like Ariel, Axis bank, Milkmaid, Daawat
                          Rice, Himalaya to name a few.
                        </Typography>
                      </Grid>

                      {/* Work Link */}
                      <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom color="#F4362A">
                          Work Link
                        </Typography>
                        <Typography variant="body1">
                          <a
                            href="https://vimeo.com/swetakutty"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://vimeo.com/swetakutty
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </section>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <img
                    alt="Logo"
                    src={SatishPhoto}
                    loading="lazy"
                    style={{
                      maxWidth: "100%", // Adjust max width as needed
                      maxHeight: "100%", // Adjust max height as needed
                      width: "auto",
                      height: "auto",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={3}>
                    {/* Director's Bio */}
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom>
                        Director
                      </Typography>
                      <Typography variant="body1" paragraph>
                        As an independent ad film director for the past 6 years,
                        Satish believes in stylised frames and organic
                        performances. He has a sharp eye for creating memorable
                        frames and capturing the true essence of performance.
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Under the mentorship of Mr. Shoojit Sircar, he spent
                        around 12+ years assisting him in over 75+ TV
                        Commercials and Feature Films. With multimedia graphics
                        and animation skillset in the armor, he has enhanced his
                        visual imagery and technical skills in filmmaking.
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Working as an Associate Director and 2nd unit director
                        in the critically acclaimed movie ‘Madras Café’ helped
                        him gather more experience and confidence to direct
                        independently. He has directed commercial films for
                        brands like BandhanBank, Uber, Rajasthan Royals, Maruti
                        Truevalue, PC Chandra Jewellers, among others. His
                        recently directed web series for Sony Liv, “Nirmal
                        Pathak ki ghar Wapsi,” has garnered great reviews.
                      </Typography>
                    </Grid>

                    {/* Work Link */}
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom color="#F4362A">
                        Work Link
                      </Typography>
                      <Typography variant="body1">
                        <a
                          href="https://vimeo.com/satishnair"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://vimeo.com/satishnair
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="Logo"
                  src={Logo}
                  loading="lazy"
                  style={{
                    maxWidth: "100%", // Adjust max width as needed
                    maxHeight: "100%", // Adjust max height as needed
                    width: "200px",
                    height: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Container maxWidth="md">
                  <Typography variant="body1" paragraph textAlign="center">
                    Join us on a journey where creativity knows no limits and
                    visionaries thrive. Discover what sets Tviek Films apart and
                    why clients trust us to bring their brands tolife in
                    unforgettable ways.
                  </Typography>
                </Container>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid textAlign={"center"}>
                <Typography variant="h4" gutterBottom color={"#F4362A"}>
                  Contact
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Dhaval Thakkar</strong>
                </Typography>
                <Typography variant="body1" paragraph   onClick={handleCall}>
                  +91 8905146473
                </Typography>
                <Typography variant="body1" onClick={handleEmail}>tviekfilms@gmail.com</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default App;
